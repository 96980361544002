import React from "react"
import { I18nextContext, Link, useI18next } from "gatsby-plugin-react-i18next"
import { Box, Divider, Flex } from "@chakra-ui/layout"
import { Text } from "@chakra-ui/react"

const LanguageSwitcher = () => {
  const { languages, originalPath } = useI18next()
  const context = React.useContext(I18nextContext)

  return (
    <Flex flexDirection="row" align="center" justify="center">
      {languages.map((lng, i) => {
        return (
          <Box
            key={i}
            color={context.language === lng ? "#ffffff" : "whiteAlpha.700"}
            fontSize={[12, 12, 13, 13]}
          >
            {lng === "en" ? (
              <Flex>
                <Link to={originalPath} language={lng}>
                  <Text
                    _hover={{ color: "white" }}
                    cursor="pointer"
                    _focus={{ color: "white" }}
                  >
                    ENG
                  </Text>
                </Link>
                <Divider
                  mx={[2, 2, "xs", "xs"]}
                  mt="2px"
                  orientation="vertical"
                  h={3}
                />
              </Flex>
            ) : (
              <Flex>
                <Link to={originalPath} language={lng}>
                  <Text
                    _hover={{ color: "white" }}
                    cursor="pointer"
                    _focus={{ color: "white" }}
                  >
                    FR
                  </Text>
                </Link>
              </Flex>
            )}
          </Box>
        )
      })}
    </Flex>
  )
}

export default LanguageSwitcher
