import theme from "@chakra-ui/theme"

const fontFamilyBase =
  '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif'
const fontSizeBase = 1.6
const lineHeightBase = 1.5
const baseline = fontSizeBase * lineHeightBase

// define your custom breakpoints
const breakpoints = ["30em", "48em", "62em", "80em"]

// add an alias for object responsive prop
breakpoints.sm = breakpoints[0]
breakpoints.md = breakpoints[1]
breakpoints.lg = breakpoints[2]
breakpoints.xl = breakpoints[3]

const customTheme = {
  ...theme,
  colors: {
    ...theme.colors,
  },
  breakpoints,
  fonts: {
    heading: fontFamilyBase,
    body: fontFamilyBase,
    mono: 'source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace',
  },
  fontSizes: {
    xs: "1.2rem",
    sm: "1.4rem", // h6
    md: "1.6rem", // h5
    lg: "1.8rem", // h4
    xl: "2rem", // h3
    "2xl": "2.4rem", // h2
    "3xl": "3rem", // h1
    "4xl": "3.6rem",
    "5xl": "4.2rem",
    "6xl": "5rem",
  },
  sizes: {
    ...theme.sizes,
    container: {
      ...theme.sizes.container,
      xl: "110rem",
    },
  },
  space: {
    ...theme.space,
    xs: `${baseline / 3}rem`,
    sm: `${baseline / 2}rem`,
    md: `${baseline}rem`, // 2.4rem
    lg: `${baseline * 2}rem`,
    xl: `${baseline * 3}rem`,
    "2xl": `${baseline * 4}rem`,
  },
}

export default customTheme
