import React from "react"
import { Helmet } from "react-helmet"
import { Box, Text } from "@chakra-ui/layout"
import { extendTheme, ChakraProvider } from "@chakra-ui/react"
import customTheme from "../../utils/theme"
import Navbar from "../Navbar/Navbar"
import Footer from "../Footer/Footer"
import { Link } from "gatsby"
import LanguageSwitcher from "../LanguageSwitcher/LanguageSwitcher"
import { useMediaQuery } from "react-responsive"
import "../../fonts/font.css"
import "./Layout.scss"

const theme = extendTheme(customTheme)

const Layout = props => {
  const isBigScreen = useMediaQuery({ query: "(min-height: 800px)" })
  return (
    <ChakraProvider theme={theme}>
      <Helmet>
        <html lang="en" />
        <title>ELISA PARRON - PHOTOGRAPHER AND MORE 🦋</title>
        <description>
          ELISA PARRON - PHOTOGRAPHER AND MORE 🦋 © Misterjuiice
        </description>
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <meta
          name="keywords"
          content="Elisaparron elisa parron numero10 photographe photo photos PARRON"
        />
        <meta
          name="description"
          content="ELISA PARRON - PHOTOGRAPHER AND MORE 🦋 © Misterjuiice"
        />
        <meta name="author" content="@misterjuiice" />
        <meta name="copyright" content="© MisterJuice Inc." />

        <meta
          name="twitter:site"
          content="ELISA PARRON - PHOTOGRAPHER AND MORE 🦋"
        />
        <meta name="twitter:creator" content="@misterjuiice" />
        <meta
          name="twitter:description"
          content="ELISA PARRON - PHOTOGRAPHER AND MORE 🦋"
        />
        <meta name="robots" content="all" />
        <meta
          name="twitter:title"
          content="ELISA PARRON - PHOTOGRAPHER AND MORE 🦋"
        />
        <meta
          name="og:title"
          content="ELISA PARRON - PHOTOGRAPHER AND MORE 🦋"
        />
        <meta name="og:url" content="https://elisaparron.art" />
        <meta name="image" content="https://elisaparron.art/favicon.png" />
        <meta name="og:image" content="https://elisaparron.art/favicon.png" />
        <meta
          name="twitter:image"
          content="https://elisaparron.art/favicon.png"
        />
        <meta name="og:image:type" content="image/png" />
        <meta
          name="og:description"
          content="ELISA PARRON - PHOTOGRAPHER AND MORE 🦋"
        />
        <meta
          name="og:site_name"
          content="ELISA PARRON - PHOTOGRAPHER AND MORE 🦋"
        />
      </Helmet>
      <Box
        fontFamily="NameTitle"
        textAlign="center"
        w="100vw"
        zIndex={100}
        pt={isBigScreen ? "md" : ["md", "md", "xs", "xs"]}
      >
        <Text
          my={["xs", "xs", "md", "md"]}
          color="whiteAlpha.800"
          fontWeight={800}
          textTransform="uppercase"
          letterSpacing={[5, 5, 10, 10]}
          fontSize={[25, 25, 32, 32]}
        >
          <Link to="/">Elisa Parron</Link>
        </Text>

        <Box
          position="absolute"
          right={[5, 5, 100, 100]}
          top={isBigScreen ? [5, 5, 85, 85] : [5, 5, 50, 50]}
        >
          <LanguageSwitcher />
        </Box>

        <Box
          position="absolute"
          bottom={10}
          left="50%"
          top={
            isBigScreen
              ? ["40%", "40%", "50%", "50%"]
              : ["42%", "42%", "45%", "45%"]
          }
          width="100%"
          transform={
            isBigScreen
              ? [
                  "translate(-50%, -40%)",
                  "translate(-50%, -40%)",
                  "translate(-50%, -50%)",
                  "translate(-50%, -50%)",
                ]
              : [
                  "translate(-50%, -42%)",
                  "translate(-50%, -42%)",
                  "translate(-50%, -45%)",
                  "translate(-50%, -45%)",
                ]
          }
        >
          {!props.navbarBottom && (
            <Navbar
              pageTitle={props.pageTitle}
              navbarBottom={props.navbarBottom}
            />
          )}
          {props.children}
        </Box>

        <Box
          position="absolute"
          bottom={5}
          left="50%"
          width="100%"
          transform="translateX(-50%)"
        >
          <Box mb={isBigScreen ? "md" : 0}>
            {props.navbarBottom && (
              <Navbar
                pageTitle={props.pageTitle}
                navbarBottom={props.navbarBottom}
              />
            )}
          </Box>
          <Footer />
        </Box>
      </Box>
    </ChakraProvider>
  )
}

export default Layout
