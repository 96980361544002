import { Box, Divider, Flex, Text, Link } from "@chakra-ui/layout"
import React from "react"

const navItems = [
  { title: "photos", link: "/photos" },
  { title: "shop", link: "https://elisaparron.shop" },
  { title: "contact", link: "/contact" },
]

const Navbar = props => {
  return (
    <Box color="whiteAlpha.700" mt={props.navbarBottom ? "md" : -35}>
      <Flex
        align="center"
        justify="center"
        m="0 auto"
        mt="sm"
        fontFamily="sans-serif"
        px="md"
        textAlign="center"
        maxW={["80%", "80%", "50%", "40%"]}
      >
        {navItems.map((e, i) => {
          return (
            <Box key={i} w="100%" mx="xs">
              <Link
                href={e.link}
                w="100%"
                activeStyle={{
                  color: "#ffffff",
                  fontWeight: 800,
                }}
              >
                <Flex py="xs" cursor="pointer" w="100%">
                  <Text
                    flex={1}
                    color={
                      e.title === props.pageTitle ? "white" : "whiteAlpha.700"
                    }
                    _hover={{
                      color: "white",
                    }}
                    fontWeight={400}
                    textTransform="uppercase"
                    textAlign="center"
                    fontSize={[14, 14, 16, 16]}
                  >
                    {e.title}
                  </Text>
                  {i !== 2 && (
                    <Divider
                      mt="0.5px"
                      ml={["sm", "sm", "xs", "xs"]}
                      borderColor="#777777"
                      orientation="vertical"
                      h={5}
                    />
                  )}
                </Flex>
              </Link>
            </Box>
          )
        })}
      </Flex>
    </Box>
  )
}

export default Navbar
