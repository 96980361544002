import { Box, Text } from "@chakra-ui/layout"
import React from "react"

const Footer = () => {
  return (
    <Box fontFamily="Poppins" color="whiteAlpha.600">
      <Text fontSize={12} fontWeight={300}>
        © {new Date().getFullYear()}{" "}
        <a
          href="https://www.instagram.com/elisaparron"
          rel="noreferrer"
          target="_blank"
        >
          Elisa Parron
        </a>{" "}
        -{" "}
        <a
          href="https://www.instagram.com/0xpierre.dev"
          rel="noreferrer"
          target="_blank"
        >
          @0xpierre.dev
        </a>
      </Text>
    </Box>
  )
}

export default Footer
